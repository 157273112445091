import {InjectionToken} from '@angular/core';

export interface ApiConfig {
  endpoint: string;
  subjectServiceUrl: string;
  searchMatchServiceUrl: string;
  orderServiceUrl: string;
  commonServiceUrl: string;
  documentsServiceUrl: string;
  notificationsServiceUrl: string;
  configsServiceUrl: string;
}

export const ApiConfigService = new InjectionToken<ApiConfig>('ApiConfig');
